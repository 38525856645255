<template>
  <nav class="bg-secondary font-primary fixed w-screen top-0 z-50">
    <div class="flex flex-wrap items-center justify-between py-4 px-8 md:px-24 lg:px-40">
      <a href="/">
        <img
          src="@/components/asset/scenera-logo.svg"
          alt="logo"
          class="object-cover w-32 md:w-44"
        />
      </a>
      <!-- website menu -->
      <div id="navbar" class="hidden w-full lg:block lg:w-auto">
        <ul
          class="font-medium list-none flex flex-col p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
        >
          <li v-for="item in menuItems" :key="item.routeName">
            <a
              :href="item.url"
              class="block py-2 px-3 text-primary font-semibold uppercase cursor-pointer rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              aria-current="page"
              >{{ item.title }}</a
            >
          </li>
        </ul>
      </div>

      <!-- Mobile menu button -->
      <button
        type="button"
        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        @click="toggleMobileMenu()"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
    </div>
    <!-- mobile menu -->
    <div
      class="flex flex-col items-end pr-8 pb-8 lg:hidden"
      :class="{ hidden: isMobileMenuOpen === false, block: isMobileMenuOpen !== true }"
    >
      <ul class="list-none">
        <li v-for="item in menuItems" :key="item.routeName">
          <a
            :href="item.url"
            class="block py-2 px-3 text-gray-900 uppercase rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
            aria-current="page"
            >{{ item.title }}</a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup>
import { ref } from 'vue';
const menuItems = [
  { title: 'home', routeName: 'home', url: '/' },
  { title: 'features', routeName: 'features', url: '/features' },
  {
    title: 'press',
    routeName: 'press',
    url: 'https://www.scenera.net/press/',
  },
  {
    title: 'about us',
    routeName: 'about-us',
    url: 'https://www.scenera.net/our-team/',
  },
  {
    title: 'contact',
    routeName: 'contact',
    url: 'https://www.scenera.net/contact-us/',
  },
];

const isMobileMenuOpen = ref(false);

const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
  console.log(isMobileMenuOpen);
};
</script>
